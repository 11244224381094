import type { App, Component } from "vue";

import type { LocaleIso, LocaleMorpheus } from "@/lib/helpers/locales";

type VueIslandConfig = Record<string, () => Promise<Component>>;

function initVueIslands(
  components: VueIslandConfig,
  appCallback?: (app: App) => void,
  locale?: LocaleIso | LocaleMorpheus,
) {
  renderVueIslands(components, appCallback, locale);
  const observer = new MutationObserver(() => {
    renderVueIslands(components, appCallback, locale);
  });
  observer.observe(document.body, { childList: true, subtree: true });
}

function renderVueIslands(
  components: VueIslandConfig,
  appCallback?: (app: App) => void,
  locale?: LocaleIso | LocaleMorpheus,
) {
  // We extract the name here so we can remove the attribute before we do something async
  // This prevents trying to render twice on the same container
  document
    .querySelectorAll<HTMLElement>("[data-component]")
    .forEach((container) => {
      const name = container.dataset.component!;
      container.removeAttribute("data-component");

      const observer = new IntersectionObserver(
        async ([entry]) => {
          if (entry?.isIntersecting) {
            observer.disconnect();
            const { renderVueIsland } = await import("@/lib/helpers/vueIsland");

            renderVueIsland(
              name,
              container,
              components[name],
              appCallback,
              locale,
            );
          }
        },
        { rootMargin: "200px" },
      );
      observer.observe(container);
    });
}

export { initVueIslands };
export type { VueIslandConfig };
